import { AppBar } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import {
  IconButton,
  Toolbar,
  Button,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import COLORS from "../constants/colors"
import FONTS from "../constants/fonts"
import NAV_ITEMS from "../constants/nav-items"
// import SIZES from "../constants/sizes"
import { navigate } from "gatsby-plugin-intl"
import MenuBurger from "../images/MenuBurger"
import NavDrawer from "./NavDrawer"
import NavBarStock from "./NavBarStock"
import logoGreen from "../images/green-metal-logo.svg"
import logoDark from "../images/green-metal-dark-logo.svg"

// const StyledTabs = withStyles({
//   indicator: {
//     display: "flex",
//     justifyContent: "center",
//     backgroundColor: "transparent",
//     height: "4px",
//     "& > div": {
//       maxWidth: SIZES.DIVIDER.WIDTH.M,
//       width: "30%",
//       borderBottom: `${SIZES.DIVIDER.HEIGHT.REGULAR}px solid ${COLORS.WHITE}`,
//     },
//   },
// })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const useStyles = makeStyles(theme => ({
  navBar: {
    position: "absolute",
    top: 30,
    paddingLeft: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  appBar: {
    position: "relative",
    margin: "0",
    paddingRight: 20,
    boxShadow: "none",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&>.MuiTabs-root": {
      minHeight: "auto",
    },
  },
  logoTab: {
    maxWidth: "none",
    opacity: 1,
    padding: "10px 50px",
    "& .MuiTouchRipple-root": {
      color: COLORS.WHITE,
      zIndex: -1,
    },
  },
  appBarSmallDevice: {
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "0",
  },
  tab: {
    minWidth: "auto",
    padding: "10px 5px",
    margin: "0 12px",
    minHeight: "auto",
    textTransform: "lowercase",
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
    // color: ({ lightBackground }) =>
    //   lightBackground ? COLORS.DARK_BLUE : COLORS.WHITE, // Function for changeing nav color depending on page
    color: COLORS.WHITE_TEXT,
    fontSize: FONTS.SIZE.M,
    opacity: 1,

    "& .MuiTouchRipple-root": {
      zIndex: -1,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: FONTS.SIZE.XXS,
      margin: "0 5px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: FONTS.SIZE.XXS,
    },
  },
  openButton: {
    marginLeft: "auto",
    "& svg": {
      transform: "scale(0.8,1.2)",
    },
    "& .MuiTouchRipple-root": {
      zIndex: -1,
    },
  },
  closeButton: {
    display: "block",
    marginRight: "auto",
    color: "inherit",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      width: "50vw",
      backgroundColor: "rgba(0, 19, 55, 0.9)",
      backdropFilter: "blur(10px)",
    },
    "& .MuiTabs-indicator": {
      left: 0,
    },
  },
  drawerTabs: {
    "& .MuiTabs-flexContainer": {
      width: "100%",
    },
  },
  drawerTab: {
    textTransform: "unset",
    // fontSize: FONTS.SIZE.S,
    maxWidth: "100%",
  },
  drawerLogoTab: {
    opacity: 1,
  },
  lightLogo: {
    width: 200,
    maxWidth: "100%",
  },
  navBarStock: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  logo: {
    width: 180,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
}))

const getShownPageIndex = currentPath => {
  for (let index = 1; index < NAV_ITEMS.length; index++) {
    if (currentPath.includes(NAV_ITEMS[index].link)) {
      return index
    }
  }
  if (currentPath === NAV_ITEMS[0].link) {
    return 0
  }
  return false
}

const Nav = props => {
  // const { formatMessage } = useIntl()
  // const { formatMessage, locale } = useIntl()
  // const theme = useTheme()
  // const smallScreen = useMediaQuery(theme.breakpoints.down(750))
  const currentPath = useLocation().pathname
  const logo = props.darkVersion ? logoDark : logoGreen;
  // useLocation().pathname.indexOf(`/${locale}`) === 0
  //   ? useLocation().pathname.slice(locale.length + 1)
  //   : useLocation().pathname
  // const [selectedPage, setSelectedPage] = useState(
  //   getShownPageIndex(currentPath)
  // )
  const classes = useStyles({
    isTransparent: getShownPageIndex(currentPath),
    lightBackground: getShownPageIndex(currentPath) === 0 ? false : true,
  })
  // const indicatorRef = useRef()

  // const logo = !getShownPageIndex(currentPath)
  //   ? data.allContentfulScanshipCompanyInfo.edges[0].node.logo.fluid
  //   : data.allContentfulScanshipCompanyInfo.edges[0].node.darkLogo.fluid

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [])

  // const handleChange = (e, index) => {
  //   const delay = index === 0 ? 0 : 300
  //   index === 0 ? setSelectedPage(false) : setSelectedPage(index)
  //   setTimeout(() => navigate(NAV_ITEMS[index].link), delay)
  // }

  return (
    <>
      {true && (
        <>
          <AppBar position="absolute" className={classes.appBarSmallDevice}>
            <NavBarStock className={classes.navBarStock} />
            <Toolbar>
              <Button onClick={() => navigate(NAV_ITEMS[0].link)}>
                {
                  <img
                    src={logo}
                    className={classes.logo} 
                    alt="Vow logo"
                  />
                }
              </Button>
              {!open && (
                <IconButton
                  className={classes.openButton}
                  onClick={() => setOpen(true)}
                >
                  <MenuBurger darkVersion={props.darkVersion} />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <NavDrawer visible={open} hide={() => setOpen(false)} />
        </>
      )}
    </>
  )
}

export default Nav
