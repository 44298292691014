const COLORS =  {
  WHITE: "#F8F8F8",
  BLACK: "#1D1D1D",
  DARK_TEXT: "#363636",
  WHITE_TEXT: "#FFFFFF",
  COLE: "#1C1C1C",
  BLUE: "#268AE5",
  DARK_BLUE: "#001337",
  LIGHT_GREY: "#F1F3F6",
  GREY: "#BBBEC4",
  RED: "#E52648",
  GREEN: "#00AC4D",
  YELLOW: "#FCE157",
}

export default COLORS
