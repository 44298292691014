const NAV_ITEMS = [
  { intlId: 'Nav.home', link: '/' },
  {
    intlId: 'Nav.news',
    link: '/news',
  },
  {
    intlId: 'Nav.ourProjects',
    link: '/our-projects',
  },
  {
    intlId: 'Nav.aboutUs',
    link: '/about-us',
    subpages: [
      { intlId: 'aboutUs.subnav.index', link: '/about-us' },
      {
        intlId: 'aboutUs.subnav.management',
        link: '/about-us/management',
      },
      {
        intlId: 'aboutUs.subnav.board',
        link: '/about-us/board',
      },
    ],
  },
  {
    intlId: 'Nav.investors',
    link: '/investors',
    subpages: [
      { intlId: 'investors.subnav.index', link: '/investors' },
      {
        intlId: 'investors.subnav.resultsAndPresentations',
        link: '/investors/results-and-presentations',
      },
      {
        intlId: 'investors.subnav.news',
        link: '/investors/news',
      },
      {
        intlId: 'investors.subnav.financialCalendar',
        link: '/investors/financial-calendar',
      },
      {
        intlId: 'investors.subnav.generalMeetings',
        link: '/investors/general-meetings',
      },
      {
        intlId: 'investors.subnav.irContacts',
        link: '/investors/ir-contacts',
      },
    ],
  },
  {
    intlId: 'Nav.careers',
    link: '/careers',
  },
]

export default NAV_ITEMS
