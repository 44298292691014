const SIZE = {
  XXXXS: 10,
  XXXS: 12,
  XXS: 14,
  XS: 16,
  S: 18,
  M: 20,
  XM: 24,
  XXM: 30,
  L: 40,
  XL: 50,
  XXL: 60,
  XXXL: 70,
  XXXXL: 90,
  XXXXXL: 100,
}

const FAMILY = {
  PRIMARY: "Roboto",
}

const WEIGHT = {
  THIN: 100,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
}

const FONTS = {
  SIZE,
  FAMILY,
  WEIGHT,
}

export default FONTS
