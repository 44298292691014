import React, { useState } from 'react';
import {
  Drawer,
  Tabs,
  Tab,
  Slide,
  makeStyles,
  Button,
  IconButton,
  Grid,
} from '@material-ui/core';
import {
  FormattedMessage,
  navigate,
} from 'gatsby-plugin-intl';
import NAV_ITEMS from '../constants/nav-items';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useLocation } from '@reach/router';
import { func, bool } from 'prop-types';

const useStyles = makeStyles(theme => ({
  NavDrawer: {
    '& >.MuiDrawer-paper': {
      width: '100%',
      backgroundColor: COLORS.BLACK,
      overflow: 'hidden',
      color: COLORS.WHITE,
      padding: '35px 0 40px',
      [theme.breakpoints.up("md")]: {
        width: "30%",
      },
    },
  },
  mainContainer: {
    height: 'inherit',
    alignContent: 'space-between',
  },
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    paddingLeft: 24,
    paddingRight: 5,
  },
  logo: {
    width: 154,
  },
  closeButton: {
    display: 'inline-block',
    color: 'inherit',
  },
  backButton: {
    color: 'inherit',
    marginLeft: 10,
    display: 'inline-block',
    '& svg': {
      fontSize: 30,
    },
  },
  forwardIcon: {
    fontSize: 30,
    color: 'inherit',
  },
  tabs: {
    width: '100%',
    '& .MuiTabs-indicator': {
      left: 0,
      backgroundColor: COLORS.WHITE,
    },
  },
  tab: {
    '&>.MuiTab-wrapper': {
      fontWeight: FONTS.WEIGHT.BOLD,
      fontSize: FONTS.SIZE.XS,
      textTransform: 'lowercase',
      textAlign: 'left',
      display: 'block',
      paddingLeft: 24,
      '&>div': {
        paddingRight: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      "&:hover": {
        color: COLORS.GREEN,
      },
    },
  },
  footer: {
    paddingLeft: 24,
  },
  iconsContainer: {
    marginBottom: 18,
    '& >*': {
      lineHeight: '0 !important',
      display: 'inline-block',
    },
    '& svg': {
      fontSize: 40,
      marginRight: 15,
    },
  },
  icon: {
    color: COLORS.WHITE,
  },
  customIcon: {
    transform: 'scale(0.75)',
    borderRadius: 5,
    backgroundColor: 'currentColor',
    '&>path': {
      fill: COLORS.BLACK,
      transform: 'scale(0.8) translate(15%,10%)',
    },
  },
  copyRights: {
    fontSize: FONTS.SIZE.XXXS,
    fontWeight: FONTS.WEIGHT.REGULAR,
  },

}))

const findPrevious = url => {
  const end = url.lastIndexOf('/');
  const res =
    url === NAV_ITEMS[0].link
      ? url
      : end === 0
      ? NAV_ITEMS[0].link
      : url.slice(0, end);
  return res;
};

const NavDrawer = ({ visible, hide }) => {
  const classes = useStyles();
  // const data = useStaticQuery(query)
  //   .allContentfulScanshipCompanyInfo.edges[0].node;
  // const {
  //   facebook,
  //   linkedIn,
  //   twitter,
  //   lightLogo: { fluid: logoFluid },
  // } = data;
  const [current, setCurrent] = useState(NAV_ITEMS[0].link);
  const [visited, setVisited] = useState([]); 
  const currentPath = useLocation().pathname;
    // useLocation().pathname.indexOf(`/${locale}`) === 0
    //   ? useLocation().pathname.slice(locale.length + 1)
    //   : useLocation().pathname;

  const isCurrentPathExistInside = menu => {
    for (let i = menu.length - 1; i >= 0; i--) {
      if (currentPath.includes(menu[i].link)) {
        return i;
      }
    }
    return false;
  };

  const menus = [];
  const addMenu = menuItems =>
    menus.push(
      <Slide
        key={
          menuItems[0].link +
          menuItems[menuItems.length - 1].link
        }
        in={menuItems[0].link === current}
        direction={
          visited.length &&
          visited.includes(menuItems[0].link)
            ? 'right'
            : 'left'
        }
        style={{
          display:
            menuItems[0].link === current ? '' : 'none',
        }}
      >
        <div label={menuItems[0].link}>
          {current !== NAV_ITEMS[0].link && (
            <IconButton
              className={classes.backButton}
              onClick={() => {
                setCurrent(findPrevious(current));
                setTimeout(
                  () =>
                    setVisited(
                      visited.slice(0, visited.length - 1)
                    ),
                  250
                );
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={isCurrentPathExistInside(menuItems)}
            className={classes.tabs}
          >
            {menuItems.map(item => {
              const haveSubpages =
                item.subpages && item.subpages.length;

              haveSubpages && addMenu(item.subpages);
              return (
                <Tab
                  key={item.link}
                  label={
                    <div>
                      <span>
                        <FormattedMessage
                          id={item.intlId}
                        />
                      </span>
                      {haveSubpages && (
                        <ChevronRightIcon
                          className={classes.forwardIcon}
                        />
                      )}
                    </div>
                  }
                  onClick={() => {
                    if (haveSubpages) {
                      setVisited(visited.concat(current));
                      setCurrent(item.link);
                    } else {
                      navigate(item.link);
                      hide();
                    }
                  }}
                  className={classes.tab}
                />
              );
            })}
          </Tabs>
        </div>
      </Slide>
    );

  addMenu(NAV_ITEMS);

  return (
    <Drawer
      open={visible}
      anchor="right"
      className={classes.NavDrawer}
    >
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={classes.header}>
          <Button
            onClick={() => navigate(NAV_ITEMS[0].link)}
          >
            {/* <Img
              fluid={logoFluid}
              className={classes.logo}
              alt="Scanship"
            /> */}
          </Button>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              hide();
              setCurrent('/');
              setVisited([]);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {menus}
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <Grid container>
          </Grid>
          <Grid item xs={12} className={classes.copyRights}>
            Vow Green Metals 2021
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

NavDrawer.propTypes = {
  visible: bool,
  hide: func,
};

export default NavDrawer;

// const query = graphql`
//   query NavDrawer2 {
//     allContentfulScanshipCompanyInfo {
//       edges {
//         node {
//           facebook
//           linkedIn
//           twitter
//           lightLogo {
//             fluid {
//               ...GatsbyContentfulFluid_noBase64
//             }
//           }
//         }
//       }
//     }
//   }
// `;
