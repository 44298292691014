import React from "react"
import "../styles/global.css"
import Nav from "./Nav"
import Footer from "./Footer"

const Layout = ({ children, darkVersion }) => {
  return (
    <>
      <Nav darkVersion={darkVersion ? true : false}/>
      {children}
      <Footer />
    </>
  )
}

export default Layout
